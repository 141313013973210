<template>
    <div class="con-wrap">
        <CarrotTitle :title="view.info.ename+'('+view.info.kname+') PBC 상세'"></CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>
                    <table class="table-col">
                        <colgroup>
                            <col width="150">
                            <col width="150">
                            <col width="150">
                            <col width="210">
                            <col width="110">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>부문</th>
                                <th>본부</th>
                                <th>팀</th>
                                <th>팀원</th>
                                <th>직급</th>
                                <th>입사일</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{ view.info.sector_name }}</td>
                                <td>{{ view.info.office_name }}</td>
                                <td>{{ view.info.team_name }}</td>
                                <td>{{ view.info.ename }}({{ view.info.kname }})</td>
                                <td>{{ view.info.position_name }}</td>
                                <td>{{ view.info.joindate }}</td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="mt-50 mb-20">
                        <span class="block-title float-left mt-10">CARROT Personal Business Commitment ({{ view.goals.wyear }}년)</span>
                        <!-- <button class="btn-default float-right">+ 항목추가</button> -->
                        <div class="clear"></div>
                    </div>

                    <table class="table-col">
                        <colgroup>
                            <col width="150">
                            <col width="*">
                            <col width="300">
                            <col width="300">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>항목</th>
                                <th>내용(핵심 KPI)</th>
                                <th>Time-bound</th>
                                <th>계량적 기여도</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th :rowspan="view.total">주요<br>업무<br>목표</th>
                                <td class="txt-left preline">
                                    {{ view.list[0].content }}
                                </td>
                                <td class="txt-left preline">
                                    {{ view.list[0].timebound }}
                                </td>
                                <td class="txt-left">
                                    {{ view.list[0].contribution }}
                                </td>
                            </tr>
                            <tr v-for="(irow, i) in view.list.slice(1, view.list.length)" :key="i">
                                <td class="txt-left preline">
                                    {{ irow.content }}
                                </td>
                                <td class="txt-left preline">
                                    {{ irow.timebound }}
                                </td>
                                <td class="txt-left">
                                    {{ irow.contribution }}
                                </td>
                            </tr>
                            <!-- <tr>
                                <td class="txt-left">
                                    3. 어학과정 안내 페이지 구축<br>
                                    - 프로젝트 관리, 화면설계서 작성, 프로젝트 검수
                                </td>
                                <td class="txt-left">
                                    프로젝트 일정 : 18년 8월 ~ 19년 5월<br>
                                    기획 일정 : 18년 8월 ~ 12월
                                </td>
                                <td class="txt-left">
                                    100%
                                </td>
                            </tr> -->
                        </tbody>
                    </table>
                    <table class="table-row mt-10">
                        <tbody>
                            <tr>
                                <th width="150">1차<br>Review<br>코멘트</th>
                                <td class="txt-left">
                                    <textarea v-if="view.hasReview1=='Y'" class="w-100per h-100px" v-model="view.review1"></textarea>
                                    <div class="preline" v-else>{{ view.review1 }}</div>
                                </td>
                            </tr>
                            <tr>
                                <th width="150">2차<br>Review<br>코멘트</th>
                                <td class="txt-left">
                                    <textarea v-if="view.hasReview2=='Y'" class="w-100per h-100px" v-model="view.review2"></textarea>
                                    <div class="preline" v-else>{{ view.review2 }}</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="mt-50 mb-20">
                        <span class="block-title float-left">CARROT 역량 계발 목표 ({{ view.goals.wyear }})</span>
                        <div class="clear"></div>
                    </div>

                    <table class="table-col">
                        <colgroup>
                            <col width="150">
                            <col width="*">
                            <col width="400">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>항목</th>
                                <th>내용</th>
                                <th>비고</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>교육 및 <br>전문지식 계발</th>
                                <td class="txt-left preline">{{ view.goals.ens_cont }}</td>
                                <td class="txt-left preline">{{ view.goals.ens_memo }}</td>
                            </tr>
                            <tr>
                                <th>창의성 및 <br>문제 해결능력</th>
                                <td class="txt-left preline">{{ view.goals.cns_cont }}</td>
                                <td class="txt-left preline">{{ view.goals.cns_memo }}</td>
                            </tr>
                            <tr>
                                <th>근태</th>
                                <td class="txt-left preline">{{ view.goals.att_cont }}</td>
                                <td class="txt-left preline">{{ view.goals.att_memo }}</td>
                            </tr>
                            <tr>
                                <th>Leadership</th>
                                <td class="txt-left preline">{{ view.goals.lds_cont }}</td>
                                <td class="txt-left preline">{{ view.goals.lds_memo }}</td>
                            </tr>
                            <tr>
                                <th>CARROT과 <br>나의 비전</th>
                                <td class="txt-left preline">{{ view.goals.myv_cont }}</td>
                                <td class="txt-left preline">{{ view.goals.myv_memo }}</td>
                            </tr>
                        </tbody>
                    </table>

                    <button class="btn-default float-left mt-30" @click="view.goList">목록</button>
                    <div  v-if="view.calcAuth()">&nbsp;</div>
                    <div v-else>
                        <button class="btn-default float-right mt-30 ml-20" @click="view.doSubmit">제출</button>
                        <button class="btn-default float-right mt-30" @click="view.tempSave">임시저장</button>
                    </div>
                    <div class="clear"></div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, onUnmounted, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import axios from '@/plugins/axios.js'


export default {
    layout:"myPIMS",
    components: {
    },
    setup() {
        const router = new useRouter();
        const route  = new useRoute();

        const pop = reactive({
            // Popup 노출 여부
            isShow: false
        });

        const view = reactive({
            // New Post
            idx  : 0,
            year : "",
            info : { },
            list : [
                { content:"", timebound:"", contribution:"" }
            ], total : 0,
            goals : { wyear:"" },

            hasReview1 : "Y",
            review1    : "",
            review1_idx : "0",

            hasReview2 : "Y",
            review2    : "",
            review2_idx : "0",
            calcAuth : () => {
              if(['Y','T2','F','T3','F2'].indexOf(view.goals.state) >= 0 ){
                return true;
              } else {
                return false;
              }
            },


            doSearch : () => {
                let params = {
                    idx : view.idx
                };
                axios.get("/rest/mypims/getMyPBCView", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        view.info = res.data;

                        view.goals = res.data.goals;
                        view.list  = view.goals.list;
                        view.total = view.goals.list.length;

                        view.hasReview1  = view.goals.has_review1;
                        view.review1     = view.goals.review1;
                        view.review1_idx = view.goals.review1_idx;
                        view.hasReview2  = view.goals.has_review2;
                        view.review2     = view.goals.review2;
                        view.review2_idx = view.goals.review2_idx;

                    } else {
                        console.info(res);
                    }
                });
            },

            tempSave : () => {
                let params = {
                    idx         : view.idx,
                    review1     : view.review1,
                    review2     : view.review2,
                    state       : "T2"
                };

                axios.post("/rest/mypims/modReview", params).then((res) => {
                    if( res.data.err == 0 ){
                        console.info(res);
                    } else {
                        console.info(res);
                    }
                });
            },

            doSubmit : () => {
                let params = {
                    idx         : view.idx,
                    review1     : view.review1,
                    review2     : view.review2,
                    state       : "F"
                };

                if( params.review1 == "" ){
                    alert("1차 Review 코멘트를 입력하세요.");
                    return;
                }
                if( params.review2 == "" ){
                    alert("2차 Review 코멘트를 입력하세요.");
                    return;
                }

                if( confirm("제출하시겠습니까? 제출 후에는 수정이 불가합니다.") == true ){
                    axios.post("/rest/mypims/modReview", params).then((res) => {
                        if( res.data.err == 0 ){
                            alert("제출하였습니다.");
                            router.push({
                                name   : "myPIMS-PBCKPIList",
                                params : route.params
                            });
                        } else {
                            console.info(res);
                        }
                    });
                }
            },

            goList : () => {
                if( view.goals.state == "F" ){
                    router.push({
                        name : "myPIMS-PBCKPIList",
                        params : route.params
                    });
                } else if( confirm("입력하신 내용이 사라집니다. 목록으로 이동하시겠습니까?") == true ){
                    router.push({
                        name : "myPIMS-PBCKPIList",
                        params : route.params
                    });
                }
            }
        });
        
        onMounted(() => {
            view.idx = route.params.idx;
            if ( !view.idx ) {
                router.back(-1);
            }

            view.doSearch();
        });

        onUnmounted(() => {

        });

        return {pop, view};
    }
}
</script>

<style lang="scss" scoped>
</style>